import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import Button from '@atlaskit/button';
import type { PopupProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import {
	DiscoverabilityPulse,
	LoomCrossFlowPopupCard,
	LoomCrossJoinPopupCard,
	LoomRecorderLoadingBlanket,
	LoomRecorderTriggerEvent,
	useLoomEntryPointErrorCallback,
	useLoomEntryPointVariant,
	useLoomRecorderTriggerListener,
} from '@confluence/loom-utils';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';

import { LoomVideoIcon } from '../VideoIcon';
import { LoomPageHeaderPlaceholder } from '../LoomPageHeaderPlaceholder';

import { PageHeaderLoomButton } from './PageHeaderLoomButton';
import { usePageHeaderLoomInsertion } from './usePageHeaderLoomInsertion';

const ENTRYPOINT_ID = 'loom-confluence-page-header';

const i18n = defineMessages({
	crossJoinEntryPointLabel: {
		id: 'page-header-loom-button.crossJoinEntryPointLabel',
		defaultMessage: 'Join your team on Loom to record a video',
		description:
			'The a11y-friendly ARIA label for the Loom button in the page header menu when a user is eligible for cross-join',
	},
	crossSellPopupCardDescription: {
		id: 'page-header-loom-button.crossSellPopupCardDescription',
		defaultMessage:
			'Save time and engage your team with a video walkthrough, demo, update, and more.',
		description: 'Description inside a popup card advertising an opportunity to try Loom',
	},
	crossSellPopupCardTitle: {
		id: 'page-header-loom-button.crossSellPopupCardTitle',
		defaultMessage: 'Share your work with a video',
		description: 'Title of a popup card advertising an opportunity to try Loom',
	},
	entryPointLabel: {
		id: 'page-header-loom-button.entryPointLabel',
		defaultMessage: 'Record a Loom video',
		description:
			'The a11y-friendly ARIA label and tooltip for the button in the page header to record a Loom video.',
	},
});

type PageHeaderLoomEntryPointComponentProps = {
	contentId: string;
	crossSellPopupPlacement?: PopupProps['placement'];
	spaceKey: string;
	contentType: string;
	flags: FlagsStateContainer;
	isCircle?: boolean;
	testId?: string;
	source?: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonSpacer = styled.div({
	minWidth: '30px',
});

const PageHeaderLoomEntryPointComponent: FC<PageHeaderLoomEntryPointComponentProps> = ({
	contentId,
	crossSellPopupPlacement,
	spaceKey,
	contentType,
	flags,
	isCircle = false,
	testId,
	source,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { entryPointVariant, error, loading } = useLoomEntryPointVariant();
	/**
	 * NONE being included here is to address a layout shift issue within the PageHeader while
	 * the entrypoint is loading. If we don't include NONE here, while a page is loading
	 * nothing will show where the entrypoint is, and then on load the entrypoint will pop in
	 * and shift the buttons next to it to the left.
	 */
	const isEntryPointEnabled = ['BETA', 'CO_USE', 'CROSS_FLOW', 'CROSS_JOIN', 'NONE'].includes(
		entryPointVariant,
	);

	const intl = useIntl();

	const loomEntryPointErrorCallback = useLoomEntryPointErrorCallback({ flags });

	const { openLoomRecorderParams, resetInsertFunctionOnUnmount } = usePageHeaderLoomInsertion({
		contentId,
		contentType,
		spaceKey,
		shouldSkipQuery: !(entryPointVariant === 'BETA' || entryPointVariant === 'CO_USE'), // skip query if it is not BETA or CO_USE.
	});

	useLoomRecorderTriggerListener(
		{
			event: LoomRecorderTriggerEvent.CROSS_FLOW_SUCCESS,
			entryPointId: ENTRYPOINT_ID,
		},
		openLoomRecorderParams,
		{
			entryPointLocation: 'pageHeader', // to match existing behavior (from the config defined in PageHeaderLoomButton.tsx)
			flags,
			resetInsertFunctionOnUnmount,
		},
	);

	if (!isEntryPointEnabled) {
		return null;
	}

	if (entryPointVariant === 'BETA' || entryPointVariant === 'CO_USE') {
		return (
			<>
				<PageHeaderLoomButton
					contentId={contentId}
					contentType={contentType}
					dataVC="loom-button"
					label={intl.formatMessage(i18n.entryPointLabel)}
					spaceKey={spaceKey}
					ssrPlaceholderIdProp={ssrPlaceholderIdProp}
					isCircle={isCircle}
					testId={testId}
					source={source}
				/>
				{/* LoomRecorderLoadingBlanket is shown when admin return from CFFE and confirm Loom is provisioned, we keep it showing until it is in the 'pre-recording' sdk state (took 2~8 seconds roughly) */}
				<LoomRecorderLoadingBlanket entryPointId={ENTRYPOINT_ID} />
			</>
		);
	}

	if (error) {
		return (
			<Button
				appearance="subtle"
				aria-label={intl.formatMessage(i18n.entryPointLabel)}
				data-vc="loom-button"
				iconAfter={<LoomVideoIcon label="" color={token('color.icon.subtle')} />}
				onClick={loomEntryPointErrorCallback}
			/>
		);
	}

	if (loading) {
		return <LoomPageHeaderPlaceholder />;
	}

	if (entryPointVariant === 'CROSS_FLOW') {
		return (
			<>
				<LoomCrossFlowPopupCard
					description={intl.formatMessage(i18n.crossSellPopupCardDescription)}
					entryPointId={ENTRYPOINT_ID}
					placement={crossSellPopupPlacement}
					sourceActionSubjectId="createLoomHeaderItem"
					title={intl.formatMessage(i18n.crossSellPopupCardTitle)}
					trigger={(triggerProps) => (
						<DiscoverabilityPulse entryPointId={ENTRYPOINT_ID} isCircle={isCircle}>
							<ButtonSpacer>
								<Button
									{...triggerProps}
									appearance="subtle"
									aria-label={intl.formatMessage(i18n.entryPointLabel)}
									data-vc="loom-button"
									iconAfter={<LoomVideoIcon label="" color={token('color.icon.subtle')} />}
									{...ssrPlaceholderIdProp}
								/>
							</ButtonSpacer>
						</DiscoverabilityPulse>
					)}
				/>
				{/* LoomRecorderLoadingBlanket is shown when admin returns from CFFE, it needs ~1 second to poll license-information to confirm if Loom is provisioned */}
				<LoomRecorderLoadingBlanket entryPointId={ENTRYPOINT_ID} />
			</>
		);
	}

	if (entryPointVariant === 'CROSS_JOIN') {
		return (
			<LoomCrossJoinPopupCard
				description={intl.formatMessage(i18n.crossSellPopupCardDescription)}
				placement={crossSellPopupPlacement}
				sourceActionSubjectId="createLoomHeaderItem"
				title={intl.formatMessage(i18n.crossSellPopupCardTitle)}
				entryPointId={ENTRYPOINT_ID}
				triggerLink={(href, { onClick, ...otherTriggerProps }) => (
					<DiscoverabilityPulse entryPointId={ENTRYPOINT_ID} isCircle={isCircle}>
						<ButtonSpacer>
							<Button
								{...otherTriggerProps}
								appearance="subtle"
								aria-label={intl.formatMessage(i18n.crossJoinEntryPointLabel)}
								data-vc="loom-button"
								href={href}
								iconAfter={<LoomVideoIcon label="" color={token('color.icon.subtle')} />}
								rel="noreferrer"
								target="_blank"
								onClick={onClick}
								{...ssrPlaceholderIdProp}
							/>
						</ButtonSpacer>
					</DiscoverabilityPulse>
				)}
			/>
		);
	}

	// This condition currently isn't possible, but explicitly handling it here in case the
	// logic defining `isEntryPointEnabled` changes without updating this rendering behavior
	return null;
};

export const PageHeaderLoomEntryPoint = withErrorBoundary({
	attribution: Attribution.CONTENT_TYPES,
})(withFlags(PageHeaderLoomEntryPointComponent));

import React, {
	type KeyboardEvent,
	type MouseEventHandler,
	type ReactNode,
	useRef,
	useCallback,
	useContext,
} from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
import type { PopupProps } from '@atlaskit/popup';
import { Inline } from '@atlaskit/primitives';

import {
	ExperienceTrackerContext,
	LOOM_CROSS_SELL_EXPERIENCE,
} from '@confluence/experience-tracker';
import { isConfluenceRedirectToLoomSDKExperimentEnabled } from '@confluence/growth-experiment-helpers';

import { useDiscoverabilityPulseActions } from '../DiscoverabilityPulse/useDiscoverabilityPulse';
import { useLoomCrossFlowRequestAccessCallback } from '../useLoomCrossFlowRequestAccessCallback';
import { LoomAdControl } from '../LoomAdControl/LoomAdControl';
import { useLoomAdControlState } from '../LoomAdControl/useLoomAdControlState';

import {
	BaseLoomPopupCard,
	type EntryPointId,
	type TriggerProps as BaseTriggerProps,
} from './BaseLoomPopupCard';

const sourceAdType = 'crossFlow';

const i18n = defineMessages({
	tryNow: {
		id: 'loom-utils.loom-cross-sell-popup-card.tryNow',
		defaultMessage: 'Try now',
		description: 'text on button that launches a cross-sell flow for Loom',
	},
});

type TriggerProps = BaseTriggerProps & {
	onClick: MouseEventHandler;
};

type LoomCrossFlowPopupCardProps = {
	description: string;
	// a unique identifier for the entrypoint
	entryPointId: EntryPointId;
	placement?: PopupProps['placement'];
	shouldRenderToParent?: boolean;
	// action subject ID of the entrypoint the popup is triggered from
	sourceActionSubjectId: string;
	title: string;
	trigger: (triggerProps: TriggerProps) => ReactNode;
};

export const LoomCrossFlowPopupCard: React.FC<LoomCrossFlowPopupCardProps> = ({
	description,
	entryPointId,
	placement,
	shouldRenderToParent,
	sourceActionSubjectId,
	title,
	trigger,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();
	const ctaRef = useRef<HTMLButtonElement | null>(null);

	const triggerCrossFlow = useLoomCrossFlowRequestAccessCallback({ sourceComponent: entryPointId });
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { isInterestedInLoom, notInterestedInLoomCallback } = useLoomAdControlState({
		touchpoint: entryPointId,
	});

	const { stopPulse } = useDiscoverabilityPulseActions();

	const onCrossFlowTriggerClick = useCallback(
		async (triggerName: string) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: triggerName,
					source: triggerName === 'tryNow' ? 'loomCrossFlowPopupCard' : sourceActionSubjectId,
					attributes: {
						adType: sourceAdType,
						entrypoint: entryPointId,
						isConfluenceRedirectToLoomSDKExperimentEnabled:
							isConfluenceRedirectToLoomSDKExperimentEnabled().isVariantCohort,
					},
				},
			}).fire();
			void stopPulse();
			await triggerCrossFlow();
		},
		[createAnalyticsEvent, entryPointId, triggerCrossFlow, sourceActionSubjectId, stopPulse],
	);

	const handleNotInterested = useCallback(async () => {
		experienceTracker.abort({
			name: LOOM_CROSS_SELL_EXPERIENCE,
			reason: 'User not interested in Loom',
			attributes: {
				sourceComponent: entryPointId,
				adType: sourceAdType,
			},
		});
		void stopPulse();
		await notInterestedInLoomCallback();
	}, [entryPointId, experienceTracker, notInterestedInLoomCallback, stopPulse]);

	if (!isInterestedInLoom) {
		return null;
	}

	return (
		<BaseLoomPopupCard
			actions={
				<Inline space="space.100">
					<LoomAdControl onDismiss={handleNotInterested} entryPointId={entryPointId} />
					<Button
						appearance="discovery"
						onClick={() => onCrossFlowTriggerClick('tryNow')}
						ref={ctaRef}
					>
						{intl.formatMessage(i18n.tryNow)}
					</Button>
				</Inline>
			}
			description={description}
			entryPointId={entryPointId}
			placement={placement}
			shouldRenderToParent={shouldRenderToParent}
			title={title}
			trigger={(popupTriggerProps) =>
				trigger({
					...popupTriggerProps,
					onClick: () => onCrossFlowTriggerClick(sourceActionSubjectId),
					onKeyDown: (e: KeyboardEvent) => {
						if (e.key === 'Tab') {
							ctaRef.current?.focus();
						}
					},
				})
			}
			sourceAdType={sourceAdType}
		/>
	);
};

import React, { useCallback, useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import { useIsReducedMotion } from '@atlaskit/motion';
import {
	Spotlight,
	SpotlightManager,
	SpotlightTarget,
	SpotlightTransition,
} from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import spotlightImage from './assets/pageHeaderSpotlightImage.png';

const i18n = defineMessages({
	dismissButton: {
		id: 'loom-onboarding.page-header-button-spotlight.dismissButton',
		defaultMessage: 'Dismiss',
		description: 'Text on the button to click to indicate you are done viewing the spotlight',
	},
	bodyGA: {
		id: 'loom-onboarding.page-header-button-spotlight.ga-content',
		defaultMessage: 'Record a Loom video to create a personal walkthrough of your content.',
		description: 'Text for the page header Loom record button GA spotlight',
	},
	buttonTextGA: {
		id: 'loom-onboarding.page-header-button-spotlight.ga-button-text',
		defaultMessage: 'Record',
		description: 'Text on the button to click and start recording a Loom',
	},
});

const GA_SCREEN_NAME = 'pageHeaderLoomButtonGAOnboardingSpotlight';

export type PageHeaderLoomButtonSpotlightProps = {
	/**
	 * spotlight trigger component.
	 */
	children?: React.ReactNode;
	/**
	 * spotlight dismiss button callback.
	 */
	onClose: () => void;
	/**
	 * spotlight CTA button callback.
	 */
	onTryClick: () => void;
	/**
	 * Shape of loom icon - default is square
	 */
	isCircle?: boolean;
};

// Backup outline in case we disable the spotlight pulse for a11y reasons (e.g. reduce motion)
const customAllyPulseStyles = xcss({
	borderRadius: '6px',
	// @ts-ignore
	boxShadow: `0 0 0 2px ${token('color.border.discovery')}`,
});

const customAllyPulseStylesCircle = xcss({
	borderRadius: '24px',
	outline: `2px solid ${token('color.border.discovery')}`,
});

export const PageHeaderLoomButtonSpotlight = ({
	children,
	onClose,
	onTryClick,
	isCircle,
}: PageHeaderLoomButtonSpotlightProps) => {
	const isReducedMotion = useIsReducedMotion();
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isClosed = useRef<boolean>(false);

	const close = useCallback(() => {
		if (!isClosed.current) {
			isClosed.current = true;
			onClose?.();
		}
	}, [onClose]);

	// Call `onClose()` when this component unmounts if it wasn't already closed in order to catch when the spotlight
	// disappears if users click outside of it. This is important because we need the parent to know when to call `stop()`
	// for the EP message so we don't block other onboarding from appearing for 5 min (the default expiration time)
	useEffect(() => {
		return () => {
			close();
		};
	}, [close]);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: GA_SCREEN_NAME,
			},
		}).fire();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDismiss = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'dismiss',
				source: GA_SCREEN_NAME,
			},
		}).fire();
		close();
	}, [close, createAnalyticsEvent]);

	const handleTryItOut = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'record',
				source: GA_SCREEN_NAME,
			},
		}).fire();
		onTryClick();
		close();
	}, [close, onTryClick, createAnalyticsEvent]);

	const bodyMessage = i18n.bodyGA;
	const buttonText = i18n.buttonTextGA;

	return (
		<SpotlightManager>
			<SpotlightTarget name="page-header-loom-button-spotlight">
				<Box xcss={[customAllyPulseStyles, isCircle && customAllyPulseStylesCircle]}>
					{children}
				</Box>
			</SpotlightTarget>
			<SpotlightTransition>
				<Spotlight
					target="page-header-loom-button-spotlight"
					targetBgColor={token('color.text.inverse')}
					pulse={!isReducedMotion}
					targetRadius={isCircle ? 24 : 6}
					shouldWatchTarget
					dialogWidth={275}
					image={spotlightImage}
					actions={[
						{
							text: intl.formatMessage(buttonText),
							onClick: handleTryItOut,
						},
						{
							text: intl.formatMessage(i18n.dismissButton),
							onClick: handleDismiss,
							appearance: 'subtle',
						},
					]}
				>
					<FormattedMessage {...bodyMessage} />
				</Spotlight>
			</SpotlightTransition>
		</SpotlightManager>
	);
};

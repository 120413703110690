import React from 'react';

import { SpotlightPulse } from '@atlaskit/onboarding';

import { useDiscoverabilityPulse } from './useDiscoverabilityPulse';

type DiscoverabilityPulseProps = {
	children: JSX.Element;
	entryPointId: string;
	isCircle?: boolean;
};

export const DiscoverabilityPulse = ({
	children,
	entryPointId,
	isCircle,
}: DiscoverabilityPulseProps) => {
	const { isPulseActive } = useDiscoverabilityPulse(entryPointId);

	if (!isPulseActive) {
		return children;
	}

	return (
		<SpotlightPulse radius={isCircle ? 24 : 3} testId="loom-cross-sell-discoverability-pulse">
			{children}
		</SpotlightPulse>
	);
};

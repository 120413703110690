import React from 'react';

import Blanket from '@atlaskit/blanket';
import Portal from '@atlaskit/portal';
import { layers } from '@atlaskit/theme/constants';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';

import { useIsLoomRecorderLoadingBlanketShown } from '../useLoomRecorderLoadingBlanket';

/**
 * This blanket component is meant to be used in conjunction with the useLoomCrossFlowRequestAccessCallback hook.
 * It will render a blanket whenever a cross-flow journey is in progress or when we are trying to bring
 * up the record SDK after cross-flow finishes.
 */
export const LoomRecorderLoadingBlanket = ({ entryPointId }: { entryPointId: string }) => {
	const { isBlanketShown, isPolling } = useIsLoomRecorderLoadingBlanketShown(entryPointId);

	// the blanket has to be on top of everything. If we use layers.blanket, it will be behind the modals.
	return isBlanketShown ? (
		<>
			<Portal zIndex={layers.spotlight() + 1}>
				<Blanket isTinted />
			</Portal>
			<UFOLoadHold name="loom-recorder-loading-blanket" hold={isPolling} />
		</>
	) : (
		<></>
	);
};

import { useCallback, useState } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

import type { EntryPointId } from '../LoomCrossSellPopupCard/BaseLoomPopupCard';

type useLoomAdControlStateProps = {
	touchpoint: EntryPointId;
};

type useLoomAdControlStateResponse = {
	isInterestedInLoom: boolean;
	notInterestedInLoomCallback: () => Promise<void>;
};

const getCachedValuesArray = (): string[] => {
	try {
		const cachedValues = localStorage.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LOOM_CROSS_FLOW_AD_CONTROL_NOT_INTERESTED,
		);
		if (cachedValues !== null && cachedValues !== 'undefined') {
			return String(cachedValues).split(',');
		}
	} catch (e) {} // Swallow any errors and return empty array

	return [];
};

/**
 * Provids capabilites to persist user's interest in Loom from different touchpoints
 *
 * @param entryPointId: The touchpoint where call originated from. Serves as the local storage value prefix
 *
 * @returns
 * boolean isInterestedInLoom used for rendering Loom entrypoints when entrypoint is configured with ad contol
 *
 * callback function to be used to update isInterestedInLoom when use clicks 'Not interested' in ad control
 */
export const useLoomAdControlState = ({
	touchpoint,
}: useLoomAdControlStateProps): useLoomAdControlStateResponse => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { userId, cloudId } = useSessionData();
	const valueToBeCached = `${touchpoint}.${userId}.${cloudId}`;

	const cachedValuesArray = getCachedValuesArray();
	const [isInterestedInLoom, setIsInterestedInLoom] = useState(
		!cachedValuesArray || !cachedValuesArray.includes(valueToBeCached),
	);

	const notInterestedInLoomCallback = useCallback(async () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'notInterested',
				source: 'loomAdControlDropdown',
				attributes: {
					adType: 'crossFlow',
					entrypoint: touchpoint,
				},
			},
		}).fire();
		// Avoid duplicate values being added to cache
		if (!isInterestedInLoom) {
			return;
		}

		// Ensure fresh cache values array by reading from storage
		const freshCachedValuesArray = getCachedValuesArray();
		freshCachedValuesArray.push(valueToBeCached);
		localStorage.setItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LOOM_CROSS_FLOW_AD_CONTROL_NOT_INTERESTED,
			freshCachedValuesArray,
		);
		setIsInterestedInLoom(false);
	}, [isInterestedInLoom, valueToBeCached, createAnalyticsEvent, touchpoint]);

	return {
		isInterestedInLoom,
		notInterestedInLoomCallback,
	};
};

import { useCallback, useContext } from 'react';

import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData } from '@confluence/session-data';

import { useLoomEntryPointVariantState } from './useLoomEntryPointVariantState';
import { useLoomRecorderLoadingBlanket } from './useLoomRecorderLoadingBlanket';
import type { EntryPointId } from './LoomCrossSellPopupCard/BaseLoomPopupCard';

type triggerCrossFlowSuccessCheckType = {
	onCrossflowFailure?: () => void;
};

interface Props {
	sourceComponent: EntryPointId;
}

export const useTriggerCrossFlowSuccessCheck = ({ sourceComponent }: Props) => {
	const { isSiteAdmin } = useContext(SPAViewContext);
	const [, { crossFlowSuccessCheck }] = useLoomEntryPointVariantState();
	const { cloudId } = useSessionData();
	const tracePolling = usePressTracing('loom.cross-flow.license-information.poll');
	const [, { setIsPollingStatus }] = useLoomRecorderLoadingBlanket();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const triggerCrossFlowSuccessCheck = useCallback(
		({ onCrossflowFailure }: triggerCrossFlowSuccessCheckType) => {
			// cross-flow would only be able to update the variant if the user is an admin
			// end-users get taken through request-trial flow, which does not provision Loom
			if (isSiteAdmin) {
				tracePolling(); // trigger the UFO tracing to start
				void setIsPollingStatus(true); // render the UFO hold until the blanket is hidden
				crossFlowSuccessCheck({
					cloudId,
					onCrossflowFailure,
					createAnalyticsEvent,
					sourceComponent,
					setIsPollingStatus,
				});
			}
		},
		[
			isSiteAdmin,
			tracePolling,
			setIsPollingStatus,
			crossFlowSuccessCheck,
			cloudId,
			createAnalyticsEvent,
			sourceComponent,
		],
	);

	return { triggerCrossFlowSuccessCheck };
};
